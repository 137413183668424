import React, { useCallback, useEffect, useState } from 'react';

import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';

import { faUserEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DialogContent,
  Grid,
  ListItemText,
  TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import {
  FormElement,
  RequiredSelectHack, StyledListItem
} from 'components';
import debounce from 'debounce-promise';
import { FormHelper, UserHelper, translate } from 'utils';
import { APPLICATION_ROLES } from 'utils/constants';

import { observer } from 'mobx-react-lite';

import { useStores } from 'hooks';
import AsyncSelect from 'react-select/async';
import { CompanyService } from 'services';
import { CustomActionButtons } from './_CustomActionButtons';
import { HeaderModal } from './_HeaderModal';

const UpdateUserForm = styled.form`
  .MuiToggleButton-root,
  .MuiToggleButton-root:hover {
    background-color: var(--white);
  }

  .MuiToggleButton-root.Mui-selected:first-child,
  .MuiToggleButton-root.Mui-selected:first-child:hover {
    background-color: var(--success-color);
  }

  .MuiToggleButton-root.Mui-selected:last-child,
  .MuiToggleButton-root.Mui-selected:last-child:hover {
    background-color: var(--error-color);
  }

  @media(min-width: 768px) {
    width: 600px;
  }
`;

export const UpdateUserModal = observer<any>(({
  onClose, onConfirm, closeOnSubmit = true, defaultValues
}) => {
  const { userStore } = useStores();
  const { scope } = userStore;
  const [selectedCompany, setSelectedCompany] = useState(defaultValues?.person?.mainCompany);

  useEffect(() => {
    if (selectedCompany) {
      return;
    }
    if (scope?.company) {
      setSelectedCompany(scope?.company);
    }
  }, [scope, selectedCompany]);

  const handleAddCompany = useCallback(value => {
    setSelectedCompany(value);
  }, []);

  const [formState, {
    text, email, tel
  }] = useFormState({
    accountId: defaultValues.accountId,
    accountActivated: defaultValues.accountActivated,
    login: defaultValues.login || '',
    firstName: defaultValues.person.firstName || '',
    lastName: defaultValues.person.lastName || '',
    firstContactEmail: defaultValues.person.contact.firstContactEmail || '',
    phoneNumber: defaultValues.person.contact.phoneNumber || '',
    occupation: defaultValues.person.occupation || '',
    address1: defaultValues.person.address.address1 || '',
    address2: defaultValues.person.address.address2 || '',
    postalCode: defaultValues.person.address.postalCode || '',
    city: defaultValues.person.address.city || '',
    country: UserHelper.getDefaultCountry(defaultValues ? defaultValues.person?.address : null),
    email: defaultValues.person.contact.technicalEmail || '',
    contactEmails: defaultValues.person.contact.contactEmails || ''
  });

  const getAsyncCompaniesOptions = useCallback(inputValue => new Promise(resolve => {
    CompanyService
      .getCompanyOptions({ freeSearch: inputValue })
      .then(response => resolve(response));
  }), []);

  const debouncedCompaniesLoadOptions = debounce(getAsyncCompaniesOptions, 500);

  const CustomOption = ({ data, innerProps }) => (
    <StyledListItem {...innerProps}>
      <ListItemText primary={data.label} />
    </StyledListItem>
  );

  // Only admin users can activate or deactivate users and cannot deactivate their own account
  const canManageActivation = () => !(!UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN]) || defaultValues.accountId === scope.accountId);

  const handleValidateModal = useCallback(e => {
    e.preventDefault();
    userStore.updateStart();

    const emailErrors = formState.errors.email;

    if (!emailErrors) {
      onConfirm({
        accountId: defaultValues.accountId,
        personId: defaultValues.personId,
        modelId: defaultValues.modelId,
        accountActivated: formState.values.accountActivated,
        login: formState.values.login,
        person: {
          ...defaultValues.person,
          address: {
            address1: formState.values.address1,
            address2: formState.values.address2,
            postalCode: formState.values.postalCode,
            city: formState.values.city
          },
          birthDate: formState.values.birthDate,
          firstName: formState.values.firstName,
          lastName: formState.values.lastName,
          mainCompany: selectedCompany,
          contact: {
            technicalEmail: formState.values.email,
            contactEmails: formState.values.contactEmails,
            phoneNumber: formState.values.phoneNumber
          },
          occupation: formState.values.occupation
        }
      });
    }

    if (closeOnSubmit) {
      onClose();
    }
  }, [userStore, formState, closeOnSubmit, onClose, onConfirm, defaultValues, selectedCompany]);

  return (
    <UpdateUserForm autoComplete="off" onSubmit={handleValidateModal}>
      <HeaderModal onClose={onClose}>
        <Grid alignItems="center" container justifyContent="space-between">
          <div>
            <FontAwesomeIcon icon={faUserEdit} />
            {`${translate('modalUserUpdate.editUser')} : ${formState.values.login}`}
          </div>
        </Grid>
      </HeaderModal>

      <DialogContent>
        <Grid container direction="column">
          <FormElement label={translate('pageUserDetail.myInfos')}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.lastName && formState.errors.lastName !== null}
                  label={translate('common.lastName')}
                  name="lastName"
                  required
                  {...text('lastName')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.firstName && formState.errors.firstName !== null}
                  label={translate('common.firstName')}
                  name="firstName"
                  required
                  {...text('firstName')}
                />
              </Grid>
            </Grid>
            <Grid container>
              <TextField
                autoComplete="new-password"
                error={formState.errors.occupation && formState.errors.occupation !== null}
                inputProps={{ maxLength: 200 }}
                label={translate('common.occupation')}
                name="occupation"
                {...text('occupation')}
              />
            </Grid>
          </FormElement>
          <FormElement label={translate('common.contact')}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                    autoComplete="new-password"
                    disabled={!defaultValues.canEditEmail}
                    error={formState.errors.contactEmails && formState.errors.contactEmails !== null}
                    helperText={formState.errors.contactEmails}
                    label={translate('common.contactEmails.modal')}
                    name="contactEmails"
                    {...text({
                      name: 'contactEmails',
                      // Additional validation for emails because useFormState validation stops at example@example
                      validate: value => FormHelper.validateEmails(value)
                    })}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.phoneNumber && formState.errors.phoneNumber !== null}
                  label={translate('common.phone')}
                  name="phoneNumber"
                  {...tel('phoneNumber')}
                />
              </Grid>
            </Grid>
          </FormElement>
          <FormElement label={translate('common.address')}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.address1 && formState.errors.address1 !== null}
                  label={translate('common.address')}
                  name="address1"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.postalCode, formState.values.city]
                  })}
                  {...text('address1')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.address2 && formState.errors.address2 !== null}
                  label={translate('common.address2')}
                  name="address2"
                  {...text('address2')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.postalCode && formState.errors.postalCode !== null}
                  label={translate('common.postalCode')}
                  name="postalCode"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.city]
                  })}
                  style={{ maxWidth: '100px' }}
                  {...text('postalCode')}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  autoComplete="new-password"
                  error={formState.errors.city && formState.errors.city !== null}
                  label={translate('common.city')}
                  name="city"
                  required={FormHelper.isFieldRequired({
                    fieldsToCheck: [formState.values.address1, formState.values.postalCode]
                  })}
                  {...text('city')}
                />
              </Grid>
              {/* This input is only used for forcing disabling autocomplete in chrome browser */}
              <input name="fakeInputForForcingDisablingAutocompleteChrome4" style={{ display: 'none' }} type="text" />
            </Grid>
          </FormElement>

          {canManageActivation() && (
            <FormElement label={translate('common.userAccount')}>
              <Grid container spacing={2}>
                <Grid item>
                  <div>
                    <ToggleButtonGroup
                        aria-label={translate('common.userAccount')}
                        exclusive
                        size="small"
                        value={formState.values.accountActivated}
                        onChange={(event, value) => {
                          if (value === null) return;
                          formState.setField('accountActivated', value);
                        }}
                    >
                      <ToggleButton aria-label={translate('common.activated')} value={true}>{translate('common.activated')}</ToggleButton>
                      <ToggleButton aria-label={translate('common.deactivated')} value={false}>{translate('common.deactivated')}</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </Grid>
                {formState.values.accountActivated && (
                  <Grid container item spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        autoComplete="new-password"
                        disabled={defaultValues.accountActivated}
                        error={formState.errors.login && formState.errors.login !== null}
                        label={translate('common.login')}
                        name="login"
                        required
                        {...text('login')}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                          autoComplete="new-password"
                          disabled={!defaultValues.canEditEmail}
                          error={formState.errors.email && formState.errors.firstContactEmail !== null}
                          helperText={formState.errors.email}
                          label={translate('common.userModal.technicalEmail')}
                          name="email"
                          required
                          type="email"
                          {...email({
                            name: 'email',
                            // Additional validation for emails because useFormState validation stops at example@example
                            validate: value => FormHelper.validateEmail(value)
                          })}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </FormElement>
          )}

          {(!defaultValues.person?.mainCompany || UserHelper.hasAccessRight([APPLICATION_ROLES.ADMIN])) && (
            <FormElement label={`${translate('common.company')} *`}>
              <AsyncSelect
                cacheOptions
                closeMenuOnSelect
                components={{ Option: CustomOption }}
                defaultOptions
                isClearable
                loadingMessage={() => translate('common.loading')}
                loadOptions={inputValue => debouncedCompaniesLoadOptions(inputValue)}
                menuPortalTarget={document.body}
                name="companies"
                noOptionsMessage={() => translate('errors.noOptions')}
                placeholder={translate('common.searchByInstitution')}
                styles={{
                  menu: base => ({ ...base, zIndex: 2000 }),
                  menuPortal: basePortal => ({ ...basePortal, zIndex: 2000 })
                }}
                value={selectedCompany}
                onChange={handleAddCompany}
              />
              <RequiredSelectHack value={selectedCompany || ''} />
            </FormElement>
          )}

        </Grid>
      </DialogContent>

      <CustomActionButtons
        isLoading={userStore.isUpdating}
        isValidated={defaultValues.person.validated}
        onClose={onClose}
      />
    </UpdateUserForm>
  );
});
