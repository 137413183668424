import {
  faEye, faFileExport, faPollH, faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid, IconButton, TableCell, Tooltip
} from '@material-ui/core';
import {
  GenericListFilters, GenericTable, SkeletonLine, TextError, Wrapper
} from 'components';
import { isAnonymous } from 'components/_commons/Quiz/QuizHelper';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ExaminationService } from 'services';
import shortid from 'shortid';
import { DocumentHelper, formatDate, translate } from 'utils';
import { API_ROUTES } from 'utils/constants';
import { FilterHelper } from 'utils/helpers/FilterHelper';
import { DropdownButton } from 'components/_commons';
import { useModal } from 'hooks';
import { QuizResultsListFilters } from './QuizResultsListFilters';

const getListHeaders = handleViewQuizResults => ([
  {
    name: 'intern',
    label: translate('common.intern'),
    template: row => {
      const isQuizAnonymous = isAnonymous(row);

      return (
        <TableCell key={shortid.generate()}>
          {isQuizAnonymous.status
            ? translate('common.anonymous')
            : isQuizAnonymous.field}
        </TableCell>
      );
    }
  },
  {
    name: 'quizName',
    label: translate('quiz.quizName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.quiz}
      </TableCell>
    )
  },
  {
    name: 'quizResultModuleName',
    label: translate('quiz.results.moduleName'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.module}
      </TableCell>
    )
  },
  {
    name: 'quizResultDate',
    label: translate('quiz.quizDate'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.startDate && formatDate(row.startDate)}
      </TableCell>
    )
  },
  {
    name: 'quizResultPoints',
    label: translate('quiz.resultPoints'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.finalScore}
      </TableCell>
    )
  },
  {
    name: 'quizResultActions',
    label: translate('common.actions'),
    width: '10px',
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>

          <Grid item>
            <Tooltip title={translate('common.preview')}>
              <IconButton edge="end" onClick={e => { e.stopPropagation(); handleViewQuizResults(row.id); }}>
                <FontAwesomeIcon icon={faEye} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>

        </Grid>
      </TableCell>
    )
  }
]);

export const QuizResultsList = observer(() => {
  const filterKey = 'quizResultList';

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [examinationsList, setExaminationsList] = useState([]);
  const [filters, setFilters] = useState(FilterHelper.getFilters(filterKey));
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [search, setSearch] = useState('');
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingAnswers, setIsExportingAnswers] = useState(false);
  const [listSize, setListSize] = useState();
  const displayModal = useModal();

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  const toggleExportQuizResultsButton = useCallback(() => {
    if (examinationsList.length > 0) {
      return setAreButtonsDisabled(false);
    }
    return setAreButtonsDisabled(true);
  }, [examinationsList]);

  useEffect(() => {
    toggleExportQuizResultsButton();
  }, [toggleExportQuizResultsButton]);

  const prepareFilters = useCallback(() => {
    let finalFilters = {};
    filters && filters.forEach(filter => {
      if (filter.key === 'trainee') {
        const { value } = filter;
        finalFilters = {
          ...finalFilters,
          [filter.key]: {
            ...value
          }
        };
      } else {
        finalFilters = {
          ...finalFilters,
          [filter.key]: filter.label
        };
      }
    });
    return finalFilters;
  }, [filters]);

  const loadQuizResultsList = useCallback(() => {
    const finalFilters = prepareFilters();
    setIsLoading(true);

    ExaminationService.getQuizResultsList({ freeSearch: search, ...finalFilters }, currentPage)
      .then(response => {
        let allExams = response.content;
        if (currentPage > 0) {
          allExams = [].concat(...examinationsList, allExams);
        }
        setExaminationsList(allExams);
        setMaxPage(response.totalPages);
        setListSize(response.totalElements);
      })
      .catch(() => enqueueSnackbar(translate('errors.UNCATCHED_ERROR'), { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [examinationsList, search, currentPage, enqueueSnackbar, prepareFilters]);

  const refreshQuizResultList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 0) {
      loadQuizResultsList();
    } else {
      setCurrentPage(0);
    }
  }, [currentPage, loadQuizResultsList]);

  useEffect(() => {
    refreshQuizResultList();
    // eslint-disable-next-line
  }, [filters, search]);

  useEffect(() => {
    loadQuizResultsList();
    // eslint-disable-next-line
  }, [currentPage]);

  const handleViewQuizResults = useCallback(examId => {
    history.push({
      pathname: API_ROUTES.ADMIN_QUIZ_RESULTS_DETAIL(examId),
      state: {}
    });
  }, [history]);

  const handleLoadMore = useCallback(() => {
    if (!isLoading && currentPage + 1 < maxPage) {
      return setCurrentPage(currentPage + 1);
    }
    return null;
  }, [isLoading, currentPage, maxPage]);

  const handleExportQuizResults = useCallback(() => {
    setIsExporting(true);

    const finalFilters = prepareFilters();
    const searchFilters = {
      ...finalFilters,
      freeSearch: search
    };

    const MAX_EXPORT_SIZE = 1000;

    if (listSize > MAX_EXPORT_SIZE) {
      ExaminationService.exportQuizResultsByMail(searchFilters)
          .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success', autoHideDuration: 5000 }))
          .catch((errorMessage) => enqueueSnackbar(errorMessage || translate('errors.exportQuizResultsError'), { variant: 'error', autoHideDuration: 5000 }))
          .finally(() => setIsExporting(false));
    } else {
      ExaminationService.exportQuizResults(searchFilters)
        .then(response => {
          const downloadLink = document.createElement('a');
          downloadLink.download = response.name;
          downloadLink.href = DocumentHelper.getExcelWithBase64(response.base64Content);
          downloadLink.click();
        })
        .catch(() => enqueueSnackbar(translate('errors.exportQuizResultsError'), { variant: 'error', autoHideDuration: 5000 }))
        .finally(() => setIsExporting(false));
    }
  }, [search, enqueueSnackbar, prepareFilters, listSize]);

  const handleExportQuizAnswers = useCallback(() => {
    setIsExportingAnswers(true);

    const finalFilters = prepareFilters();
    const searchFilters = {
      ...finalFilters,
      freeSearch: search
    };

    ExaminationService.exportQuizAnswers(searchFilters)
        .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success' }))
        .catch(errorMessage => enqueueSnackbar(errorMessage, { variant: 'error' }))
        .finally(() => setIsExportingAnswers(false));
  }, [search, enqueueSnackbar, prepareFilters]);

  const exportAllAnswersModal = useCallback(() => {
    const finalFilters = prepareFilters();
    displayModal({
      type: 'ALL_ANSWERS_MODAL',
      filters: { freeSearch: search, ...finalFilters }
    });
  }, [displayModal, search, prepareFilters]);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <QuizResultsListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  const renderButtonContainer = () => (
    <Grid item>
      <DropdownButton icon={faFileExport} label={'dropdownButton.exportActions'}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Tooltip title={translate('quiz.results.exportQuizResults')}>
              <Button
                  color="secondary"
                  disabled={areButtonsDisabled}
                  startIcon={(
                    <FontAwesomeIcon
                          icon={isExporting ? faSpinner : faFileExport}
                          spin={isExporting}
                      />
                  )}
                  variant="contained"
                  onClick={handleExportQuizResults}
              >
                {translate('button.exportResults')}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={translate('quiz.results.exportQuizAnswers')}>
              <Button
                  color="secondary"
                  disabled={areButtonsDisabled}
                  startIcon={(
                    <FontAwesomeIcon
                          icon={isExportingAnswers ? faSpinner : faFileExport}
                          spin={isExportingAnswers}
                      />
                  )}
                  variant="contained"
                  onClick={handleExportQuizAnswers}
              >
                {translate('button.exportAnswers')}
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={translate('quiz.results.exportAllAnswersForAQuiz')}>
              <Button
                  color="secondary"
                  disabled={areButtonsDisabled}
                  startIcon={(
                    <FontAwesomeIcon
                          icon={isExportingAnswers ? faSpinner : faFileExport}
                          spin={isExportingAnswers}
                      />
                  )}
                  variant="contained"
                  onClick={exportAllAnswersModal}
              >
                {translate('button.exportAllAnswersForAQuiz')}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </DropdownButton>
    </Grid>
  );

  return (
    <>
      <Wrapper>
        <PageTitle
          title={translate('pageQuizResultsList.title')}
          titleRight={renderButtonContainer()}
        />

        <GenericListFilters
          ComponentFilter={renderGenericFilters}
          dataTour="step-quizResultsList-filter"
          filterKey={filterKey}
          filters={filters}
          search={search}
          setFilters={setFilters}
          setSearch={setSearch}
          tooltipInfo="pageQuizResultsList.searchTooltip"
          withDrawer
        />

        <div data-tour="step-admin-examinationsList">
          {!isLoading && examinationsList.length === 0
            ? (
              <Grid alignItems="center" container direction="column">
                <FontAwesomeIcon color="var(--primary-color)" icon={faPollH} size="3x" />
                <TextError>{translate('errors.noQuizResult')}</TextError>
              </Grid>
            ) : (
              <GenericTable
                dataCy="examinationsList"
                hasMore={currentPage + 1 < maxPage}
                headers={getListHeaders(handleViewQuizResults)}
                isLoading={isLoading}
                loadMore={handleLoadMore}
                rows={examinationsList}
                total={listSize}
              />
            )}
        </div>

        {isLoading && examinationsList.length === 0 && <SkeletonLine />}
      </Wrapper>
    </>
  );
});
