import { faEdit, faSpinner, faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Grid, IconButton, TableCell, Tooltip
} from '@material-ui/core';
import {
  GenericListContainer, GenericListFilters, GenericTable, SkeletonLine, TextError
} from 'components';
import { useModal } from 'hooks';
import { useSnackbar } from 'notistack';
import { PageTitle } from 'pages';
import React, { useCallback, useEffect, useState } from 'react';
import Media from 'react-media';
import { useHistory } from 'react-router-dom';
import { CompanyService } from 'services';
import shortid from 'shortid';
import { DocumentHelper, RequestHelper, translate } from 'utils';
import { API_ROUTES } from 'utils/constants';

const listHeaders = handleEditCompany => [
  {
    name: 'corporateName',
    label: translate('common.corporateName'),
    isSortable: true,
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.name || '-'}
      </TableCell>
    )
  },
  {
    name: 'identifier',
    label: translate('common.identifier'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.identifier || '-'}
      </TableCell>
    )
  },
  {
    name: 'accountUsage',
    label: translate('common.accountsRequested'),
    template: row => (
      <TableCell key={shortid.generate()}>
        {row.databilanAccountLimit
          ? `${translate('pageCompanyList.usedAccountsDatabilan', { total: row.databilanOperatorAccountsTotal, allowed: row.databilanAccountLimit })}`
          : '-'}
      </TableCell>
    )
  },
  {
    name: 'companyActions',
    label: translate('common.actions'),
    template: row => (
      <TableCell key={shortid.generate()}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Tooltip title={translate('common.editItem')}>
              <IconButton color="primary" edge="end" onClick={e => { e.stopPropagation(); handleEditCompany(row); }}>
                <FontAwesomeIcon icon={faEdit} size="xs" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </TableCell>
    )
  }
];

const MAX_EXPORT_SIZE = 500;

export const CompanyList = () => {
  const history = useHistory();
  const displayModal = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companyListSize, setCompanyListSize] = useState(0);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    setCurrentPage(0);
  }, [search]);

  const getCompanyList = useCallback(() => {
    setIsLoading(true);
    CompanyService.getCompanyList({ freeSearch: search }, currentPage)
      .then(response => {
        const allCompanies = response.content;
        if (currentPage === 0) {
          setCompanyList(allCompanies);
        } else {
          setCompanyList([].concat(...companyList, allCompanies));
        }
        setCompanyListSize(response.totalElements);
        setMaxPage(response.totalPages);
      })
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [companyList, currentPage, search, enqueueSnackbar, setIsLoading]);

  const reloadList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    if (currentPage === 0) getCompanyList();
    else setCurrentPage(0);
  }, [currentPage, getCompanyList]);

  useEffect(() => {
    getCompanyList();
  // eslint-disable-next-line
  }, [search, currentPage]);

  const editOrCreateCompany = useCallback(form => {
    if (form.id) {
      return CompanyService.updateCompany(form.id, form)
        .then(() => {
          enqueueSnackbar(translate('confirms.companyList.update'), { variant: 'success' });
          reloadList();
        })
        .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    }

    return CompanyService.createCompany(form)
      .then(() => {
        enqueueSnackbar(translate('confirms.companyList.create'), { variant: 'success' });
        reloadList();
      })
      .catch(error => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
  }, [reloadList, enqueueSnackbar]);

  const handleEditOrCreateCompany = useCallback(row => {
    displayModal({
      type: 'CREATE_COMPANY',
      onConfirm: editOrCreateCompany,
      defaultValues: row.id ? row : {},
      closeOnSubmit: true
    });
  }, [displayModal, editOrCreateCompany]);

  const handleShowDetails = useCallback(row => {
    history.push(API_ROUTES.COMPANY_TREE_DETAILS(row.id));
  }, [history]);

  const exportCompaniesToExcel = useCallback(() => {
    setIsExporting(true);

    if (companyListSize > MAX_EXPORT_SIZE) {
      CompanyService.exportCompaniesAsynchronous({ freeSearch: search })
          .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success', autoHideDuration: 5000 }))
          .catch((errorMessage) => enqueueSnackbar(errorMessage || translate('errors.exportCompanyError'), { variant: 'error', autoHideDuration: 5000 }))
          .finally(() => setIsExporting(false));
    } else {
      // enqueueSnackbar(translate('companyList.export.tooManyRowsForExport'), { variant: 'error' });
      CompanyService.exportCompanies({ freeSearch: search }).then(response => {
        const downloadLink = document.createElement('a');
        downloadLink.href = DocumentHelper.getExcelWithBase64(response.base64Content);
        downloadLink.download = response.name;
        downloadLink.click();
      })
          .catch(RequestHelper.networkErrorHandler)
          .finally(() => setIsExporting(false));
    }
  }, [companyListSize, search, enqueueSnackbar]);

  const handleLoadMore = useCallback(() => !isLoading && setCurrentPage(currentPage + 1), [currentPage, isLoading]);

  const renderButtonContainer = useCallback(() => (
    <div className="buttonsContainer">
      <Button
        color="primary"
        data-cy="createCompany"
        data-tour="step-admin-companyCreate"
        startIcon={<FontAwesomeIcon icon={faStoreAlt} />}
        variant="contained"
        onClick={handleEditOrCreateCompany}
      >
        {translate('button.create')}
      </Button>
      <Media
        query="(min-width: 768px)"
        render={() => (
          <Button
            color="secondary"
            data-cy="exportCompanies"
            data-tour="step-admin-exportCompanies"
            disabled={isExporting}
            startIcon={<FontAwesomeIcon icon={isExporting ? faSpinner : faFileExcel} spin={isExporting} />}
            variant="contained"
            onClick={exportCompaniesToExcel}
          >
            {translate('button.export')}
          </Button>
        )}
      />
    </div>
  ), [isExporting, handleEditOrCreateCompany, exportCompaniesToExcel]);

  return (
    <GenericListContainer>
      <PageTitle
        title={translate('pageCompanyList.title')}
        titleRight={renderButtonContainer()}
      />

      <GenericListFilters
        dataTour="step-admin-companyFilters"
        search={search}
        setSearch={setSearch}
        tooltipInfo="pageCompanyList.searchTooltip"
      />

      <div data-tour="step-admin-companyList">
        {!isLoading && companyList && companyList.length === 0
          ? (
            <Grid alignItems="center" container direction="column">
              <FontAwesomeIcon color="var(--primary-color)" icon={faStoreAlt} size="3x" />
              <TextError>{translate('errors.noCompany')}</TextError>
              <Button
                color="primary"
                data-cy="createCompany"
                startIcon={<FontAwesomeIcon icon={faStoreAlt} size="sm" />}
                variant="contained"
                onClick={handleEditOrCreateCompany}
              >
                {translate('button.createCompany')}
              </Button>
            </Grid>
          ) : (
            <Media query="(max-width: 768px)">
              {matches => (
                <GenericTable
                  dataCy="companyList"
                  hasMore={currentPage + 1 < maxPage}
                  headers={matches
                    ? [...listHeaders(handleEditOrCreateCompany)].splice(0, 3)
                    : listHeaders(handleEditOrCreateCompany)}
                  isLoading={isLoading}
                  loadMore={handleLoadMore}
                  rows={companyList}
                  total={companyListSize}
                  onRowClick={handleShowDetails}
                />
              )}
            </Media>
          )}
      </div>

      {isLoading && companyList.length === 0 && <SkeletonLine />}
    </GenericListContainer>
  );
};
